<template>
  <div>
    <!--<div id="ai-message" class="relative flex flex-col lg:flex-row justify-between items-justify lg:items-center rounded-lg border-2 p-4 pt-3 md:p-6 md:pt-5 lg:p-4 lg:pt-3 xl:p-6 xl:pt-5 gap-4" v-if="this.changelogSuggestions == 'true' && this.showAiMessage == 'true' && this.subscription_valid == 'true'">
      <div class="flex flex-col gap-2" style="max-width: 32rem;">
        <p class="font-bold text-xl text-gray-700">FeedBear can write your changelog entries</p>
        <p class="text-gray-500">Use the power of AI to write your changelog entries, save your time to make better products for your customers</p>
      </div>
      <button class="btn-main text-md flex justify-center items-justify rounded-lg whitespace-nowrap px-2 xl:px-14" @click.prevent="suggestChangelog()">
        Try it now!
      </button>
      <button class="absolute top-0 right-0" style="height: 35px; width: 35px; padding-top: 4px; padding-left: 4px;" @click.prevent="closeAIMessage()">
        <svg fill="grey" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>-->
    <div class="mt-4">
      <label for="changelog_entry_title" class="label">Title</label>
      <input id="title" placeholder="Something short and descriptive" v-model="titleContent" type="text"
        name="changelog_entry[title]" class="input">
      <div v-if="errors.title" class="error_show">Please fill out this field.</div>
    </div>
    <div class="mt-4">
      <label for="changelog_entry_body" class="label">Body</label>
      <div class="changelog-editor mt-1">
        <VueTrix inputId="changelog_entry[body]" inputName="changelog_entry[body]" v-model="editorContent"
          class="input px-0 focus-within:ring" @trix-attachment-add="uploadFile"
          @trix-attachment-remove="destroyFile" />
      </div>
      <div v-if="errors.body" class="error_show">Please fill out this field.</div>
    </div>
    <ChangelogSuggestModal :show-suggestion-modal="this.showSuggestionModal && this.subscription_valid == 'true'" @close-modal="closeModal()"/>
  </div>
</template>

<script>
import VueTrix from "vue-trix";
import EventBus from "event_bus";
import ChangelogSuggestModal from "./changelog_suggest_modal.vue"

export default {
  props: ["initial-content", "title", "show-ai-message", "subscription_valid", "show-suggestion-modal", "changelog-suggestions"],
  data: function () {
    return {
      editorContent: this.initialContent || "",
      titleContent: this.title || "",
      errors: { title: false, body: false },
      showSuggestionModal: this.showSuggestionModal,
      posts: [],
    };
  },
  components: { VueTrix, ChangelogSuggestModal },
  created() {
    EventBus.$on("errorChangelog", (errors) => {
      this.errors = errors;
    });
  },
  mounted() {
    EventBus.$emit("changelogTextAdded", {
      body: !!this.editorContent,
      title: !!this.titleContent,
    });
  },
  methods: {
    updateEditorContent(value) {
      EventBus.$emit("changelogTextAdded", {
        body: !!this.editorContent,
        title: !!this.titleContent,
      });
    },
    uploadFile(event) {
      let file = event.attachment.file;
      if (!file) return;
      // TODO: Check size, do not upload anything above 5MB

      let data = new FormData();
      data.append("image", file);

      Rails.ajax({
        url: "/updates/images",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log(data);
          console.log("Added Image");

          let attributes = {
            url: data.image_link,
            href: data.image_link,
            blob_id: data.image_id,
          };
          event.attachment.setAttributes(attributes);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    destroyFile(event) {
      let blob_id = event.attachment.attachment.attributes.values.blob_id;

      Rails.ajax({
        url: "/updates/images/" + blob_id,
        type: "DELETE",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log("Destroy Image");
          console.log(data);
        },
        error: (data, status) => {
          console.log("Destroy Image");
          console.log(data);
        },
      });
    },
    suggestChangelog(){
      this.showSuggestionModal = true;
    },
    closeAIMessage(){
      Rails.ajax({
        url: `/changelog/remove_ai_message`,
        type: "POST",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          document.getElementById('ai-message').remove();
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    closeModal(){
      this.showSuggestionModal = false;
    }
  },
  watch: {
    editorContent: {
      handler: "updateEditorContent",
    },
    titleContent: {
      handler: "updateEditorContent",
    },
  },
};
</script>

<style>
trix-editor:empty:not(:focus)::before {
  color: #a9a9a9;
}

.error_show {
  color: red;
  margin-left: 2px;
}

.trix-content .attachment--preview .attachment__caption {
  margin-top: 12px;
}
</style>
