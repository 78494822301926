<template>
  <div>
    <a
      @click.prevent="castVote()"
      href="#"
      class="text-neutral-600 flex flex-col items-center justify-center border p-2 mr-2 hover:no-underline hover:bg-dblue-100 hover:bg-opacity-50 rounded-lg transition duration-200 ease-in-out"
      :class="{ 'text-project-500': alreadyVoted }"
    >
      <svg
        class="w-8 h-8"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div
        class="font-bold leading-none text-lg -mt-1 mb-1"
        id="post-upvotes"
        v-if="!originalPost.board.allow_vote_count || currentProjectAdmin"
      >
        {{ upvotes }}
      </div>
    </a>

    <div class="flex flex-col" id="roadmap-post-data">
      <div class="flex items-center">
        <simple-svg
          :filepath="privateIcon"
          width="16px"
          height="16px"
          class="mr-1"
          v-if="post.is_private"
        />
        <a
          :href="post.url"
          id="post-title"
          class="text-dblue-500 font-medium text-lg hover:no-underline hover:text-project-500 transition duration-150 ease-in-out"
          >{{ post.title }}</a
        >
      </div>
      <span class="text-sm text-dblue-400 mt-1" id="post-board">{{
        post.board.name
      }}</span>
    </div>
  </div>
</template>

<script>
import EventBus from "event_bus";
import privateIcon from "images/icons/eye-slash.svg";

export default {
  props: [
    "original-upvotes",
    "current-user",
    "original-post",
    "allow-anonymous",
    "current-project-admin",
  ],
  data: function () {
    return {
      post: this.originalPost,
      upvotes:
        this.originalPost.cached_votes_up + this.originalPost.manual_upvotes,
      alreadyVoted: this.originalPost.current_user_voted_for,
      lines: 1,
      ip_address: "",
      privateIcon,
    };
  },
  components: { privateIcon },
  methods: {
    castVote: function () {
      var vote_post_url =
        "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote";
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip_address = ip;
        });
      let data = new FormData();
      data.append("ip_address", this.ip_address);
      if (this.allowAnonymous || this.currentUser) {
        if (!this.currentUser) {
          const lastVoteTime = localStorage.getItem('lastVoteTime_' + this.post.id);
          const currentTime = new Date().getTime();

          if (lastVoteTime && currentTime - lastVoteTime < 1000) {
            return;
          }

          localStorage.setItem('lastVoteTime_' + this.post.id, currentTime);
        }

        Rails.ajax({
          url: vote_post_url,
          type: "POST",
          data: data,
          dataType: "json",
          async: true,
          beforeSend: function () {
            return true;
          },
          success: (data) => {
            if (data.msge == "annonymously") {
              // document.getElementById("annony").classList.remove("hidden");
            }

            if (data.status == 'unprocessable_entity') {
              console.log("Error voting:", data);
              return;
            }

            if (data.votes) {
              EventBus.$emit("postRoadmapVotes", {
                id: this.post.id,
                total: data.votes,
              });
              this.alreadyVoted = !this.alreadyVoted;
              this.upvotes = data.votes;
            }
          },
          error: (data, status) => {
            console.log(data);
          },
        });
      } else {
        EventBus.$emit("openRegModalSignup");
      }
    },
  },
};
</script>

<style scoped></style>
