<template>
  <div>
    <a
      @click.prevent="castVote()"
      href="#"
      class="text-dblue-400 text-lg flex flex-col items-center justify-center p-2 mr-4 hover:no-underline border border-neutral-200 hover:bg-dblue-100 hover:bg-opacity-50 hover:border-transparent rounded-lg transition duration-200 ease-in-out"
      :class="{ 'text-project-500': alreadyVoted }"
    >
      <svg
        class="w-8 h-8"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div
        class="leading-none text-lg -mt-1 mb-1 font-semibold"
        :class="{ 'font-bold': alreadyVoted }"
        v-if="!allowVoteCount || currentProjectAdmin"
        id="post-upvote-count"
      >
        {{ upvotes }}
      </div>
    </a>
  </div>
</template>

<script>
import EventBus from "event_bus";

export default {
  props: ["current-user", "original-post", 'allow-anonymous', "allow-vote-count", "current-project-admin"],
  data: function () {
    return {
      post: this.originalPost,
      upvotes:
        this.originalPost.cached_votes_up + this.originalPost.manual_upvotes,
      alreadyVoted: this.originalPost.current_user_voted_for,
      ip_address: "",
    };
  },
  mounted () {
    EventBus.$on("updateUpvotes", (post) => {
      this.upvotes = post.cached_votes_up + post.manual_upvotes
    });
  },
  methods: {
    castVote: function () {
      var vote_post_url =
      "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote";
      fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        this.ip_address = ip;
      });
      let data = new FormData();
      data.append("ip_address", this.ip_address);
      if (this.allowAnonymous || this.currentUser) {
        Rails.ajax({
          url: vote_post_url,
          type: "POST",
          data: data,
          dataType: "json",
          beforeSend: function () {
            return true;
          },
          success: (data) => {
            if (data.msge == "annonymously") {
              // document.getElementById("annony").classList.remove("hidden");
            }
            this.alreadyVoted = !this.alreadyVoted;

            if (data.votes) {
              this.upvotes = data.votes;
            }
          },
          error: (data, status) => {
            console.log(data);
          },
        });
      }
      else {
        EventBus.$emit("openRegModalSignup");
      }
    },
  },
};
</script>

<style scoped></style>
