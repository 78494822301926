<template>
  <div class="bg-white rounded-lg border">
    <div
      class="px-4 py-4 border-b rounded-t-lg border-neutral-200"
      :class="{ 'bg-green-100 border-green-200': suggestionsShown }"
      id="board-dropdown-container"
    >
      <h3 v-if="suggestionsShown" class="flex items-center">
        <svg
          class="h-5 w-5 mx-2"
          aria-hidden="true"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M569.354 231.631C512.969 135.949 407.81 72 288 72 168.14 72 63.004 135.994 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.031 376.051 168.19 440 288 440c119.86 0 224.996-63.994 281.354-159.631a47.997 47.997 0 0 0 0-48.738zM288 392c-75.162 0-136-60.827-136-136 0-75.162 60.826-136 136-136 75.162 0 136 60.826 136 136 0 75.162-60.826 136-136 136zm104-136c0 57.438-46.562 104-104 104s-104-46.562-104-104c0-17.708 4.431-34.379 12.236-48.973l-.001.032c0 23.651 19.173 42.823 42.824 42.823s42.824-19.173 42.824-42.823c0-23.651-19.173-42.824-42.824-42.824l-.032.001C253.621 156.431 270.292 152 288 152c57.438 0 104 46.562 104 104z"
          /></svg
        ><span>{{ $t("We found some similar ideas") }}</span>
      </h3>

      <div
        v-if="!suggestionsShown && !this.nofilter"
        class="flex items-stretch sm:items-center justify-start flex-col sm:flex-row"
        id="all-dropdown-board"
      >
        <SelectOneDropdown
          :name="$t('Order')"
          :values="sortOptions"
          :currentValue="sort"
          @valueChange="changeSort"
          class="mb-2 sm:mb-0 sm:mr-2 order-dropdown-board"
          id="order-dropdown-board"
        >
          <svg
            slot="icon"
            class="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
            ></path>
          </svg>
        </SelectOneDropdown>
        <SelectOneDropdown
          :name="$t('Status')"
          :values="statusComp"
          :default-status="defaultStatuses"
          :currentValue="filter"
          @valueChange="changeFilter"
          :isAdmin="currentProjectAdmin"
          class="mb-2 sm:mb-0 sm:mr-2 status-dropdown-board"
          id="status-dropdown-board"
        >
          <div
            slot="icon"
            class="w-2 h-2 mr-1 rounded-full"
            :style="
              'background-color: ' + (currentFilter && currentFilter.color)
            "
          ></div>
        </SelectOneDropdown>
        <SelectMultipleDropdown
          v-if="showTags"
          :all-tags="tags"
          :selected-tags="selectedTags"
          @tagsChange="updateSelectedTags"
          class="mb-2 sm:mb-0 sm:mr-2 tags-dropdown-board"
          id="tags-dropdown-board"
        >
          <simple-svg
            slot="icon"
            :filepath="tagsIcon"
            width="16px"
            height="16px"
          />
        </SelectMultipleDropdown>

        <div
          class="flex items-center gap-2 sm:ml-auto"
          id="my-posts-check"
          v-if="currentUser"
        >
          <div class="">
            <input
              type="checkbox"
              name="mine"
              id="mine"
              v-model="showOnlyMine"
              class="custom-checkbox cursor-pointer"
            />
            <label
              for="mine"
              class="filter-label flex-1 cursor-pointer text-neutral-800"
            >
              {{ $t("My ideas") }}</label
            >
          </div>

          <div class="ml-2" v-if="currentProjectAdmin">
            <input
              type="checkbox"
              name="private_ideas"
              id="private_ideas"
              v-model="showOnlyPrivate"
              class="custom-checkbox cursor-pointer"
            />
            <label
              for="private_ideas"
              class="filter-label flex-1 cursor-pointer text-neutral-800"
            >
              {{ $t("Private ideas") }}</label
            >
          </div>
        </div>
      </div>

      <h3 v-if="this.nofilter">
        <slot name="header"></slot>
      </h3>
    </div>
    <div
      v-if="noPosts && !showLoading"
      class="flex flex-col items-center justify-center p-10"
      id="no-post-message"
    >
      <div class="flex flex-col gap-5 w-full max-w-sm mx-auto">
        <div class="opacity-50 border-2 border-neutral-200 p-5 rounded-xl w-full flex items-center">
          <div class="p-1 bg-neutral-200 rounded-lg flex items-center">
              <svg
                class="w-8 h-8 opacity-20"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
            </svg>
          </div>
          <div class="flex-grow ml-4">
            <div class="h-4 bg-neutral-200 rounded w-1/2"></div>
            <div class="h-4 bg-neutral-200 rounded w-3/4 mt-2"></div>
          </div>
        </div>
        <div class="border-2 p-5 rounded-xl w-full flex items-center shadow-lg" style="border-color: var(--accent-color)">
          <div class="p-1 bg-neutral-200 rounded-lg flex items-center">
              <svg
                class="w-8 h-8 opacity-20"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
            </svg>
          </div>
          <div class="flex-grow ml-4">
            <div class="h-4 bg-neutral-200 rounded w-1/2"></div>
            <div class="h-4 bg-neutral-200 rounded w-3/4 mt-2"></div>
          </div>
        </div>
        <div class="opacity-50 border-2 border-neutral-200 p-5 rounded-xl w-full flex items-center">
          <div class="p-1 bg-neutral-200 rounded-lg flex items-center">
              <svg
                class="w-8 h-8 opacity-20"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
            </svg>
          </div>
          <div class="flex-grow ml-4">
            <div class="h-4 bg-neutral-200 rounded w-1/2"></div>
            <div class="h-4 bg-neutral-200 rounded w-3/4 mt-2"></div>
          </div>
        </div>
      </div>
      <p class="mt-6 text-black font-medium">
        {{ $t("Let's add some ideas to get started") }}
      </p>
    </div>

    <div
      v-if="
        sortedAndFilteredPosts.length == 0 && posts.length !== 0 && !showLoading
      "
      class="flex flex-col items-center justify-center p-10"
      id="no-post-fit-message"
    >
      <div class="flex flex-col gap-5 w-full max-w-sm mx-auto">
        <div class="opacity-50 border-2 border-neutral-200 p-5 rounded-xl w-full flex items-center">
          <div class="p-1 bg-neutral-200 rounded-lg flex items-center">
              <svg
                class="w-8 h-8 opacity-20"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
            </svg>
          </div>
          <div class="flex-grow ml-4">
            <div class="h-4 bg-neutral-200 rounded w-1/2"></div>
            <div class="h-4 bg-neutral-200 rounded w-3/4 mt-2"></div>
          </div>
        </div>
        <div class="border-2 p-5 rounded-xl w-full flex items-center shadow-lg" style="border-color: var(--accent-color)">
          <div class="p-1 bg-neutral-200 rounded-lg flex items-center">
              <svg
                class="w-8 h-8 opacity-20"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
            </svg>
          </div>
          <div class="flex-grow ml-4">
            <div class="h-4 bg-neutral-200 rounded w-1/2"></div>
            <div class="h-4 bg-neutral-200 rounded w-3/4 mt-2"></div>
          </div>
        </div>
        <div class="opacity-50 border-2 border-neutral-200 p-5 rounded-xl w-full flex items-center">
          <div class="p-1 bg-neutral-200 rounded-lg flex items-center">
              <svg
                class="w-8 h-8 opacity-20"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
            </svg>
          </div>
          <div class="flex-grow ml-4">
            <div class="h-4 bg-neutral-200 rounded w-1/2"></div>
            <div class="h-4 bg-neutral-200 rounded w-3/4 mt-2"></div>
          </div>
        </div>
      </div>
      <p class="mt-6 text-black font-medium">
        {{ $t("No ideas fit chosen criteria") }}
      </p>
      <p class="mt-2 text-neutral-800">
        {{ $t("Try changing the filters or adding a new idea") }}
      </p>
    </div>

    <div v-if="showLoading" class="loading-div">
      <div class="wrapper">
        <div v-for="n in 7" :key="n" class="wrapper-cell">
          <div class="image"></div>
          <div class="text">
            <div class="text-line-1"></div>
            <div class="text-line-2"></div>
          </div>
        </div>
      </div>
    </div>
    <transition-group
      v-show="!showLoading"
      name="list"
      class="py-5 flex flex-col gap-6"
      tag="div"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="10"
      id="all-posts"
      appear
    >
      <div
        is="post"
        v-for="post in paginatedPosts"
        :originalPost="post"
        :currentUser="currentUser"
        :current-project-admin="currentProjectAdmin"
        :show-board="showBoard"
        :key="post.id"
        :allow-anonymous="allowAnonymous"
        :allow-vote-count="allowVoteCount"
        :status-name="statusName"
        :id="'board-post-' + post.id"
        :class="'board-post-' + post.id"
        is-board="isBoard"
      ></div>
    </transition-group>
  </div>
</template>

<script>
var infiniteScroll = require("vue-infinite-scroll");

import Post from "./post.vue";
import noPostsIcon from "images/empty_posts.png";
import tagsIcon from "images/tags.svg";
import EventBus from "event_bus";
import SelectOneDropdown from "./components/select_one_dropdown";
import SelectMultipleDropdown from "./components/select_multiple_dropdown";

import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

export default {
  props: [
    "current-user",
    "current-project-admin",
    "nofilter",
    "show-board",
    "all-tags",
    "show-tags",
    "allow-anonymous",
    "post-status",
    "status-ids",
    "allow-vote-count",
    "status-name",
    "post-board",
    "is-board",
  ],
  data: function () {
    return {
      posts: [],
      originalPosts: [],
      currentUserVotes: [],
      noPosts: false,
      noPostsIcon,
      tagsIcon,
      sort: "trend_score",
      filter: "Not done",
      showOnlyMine: false,
      showOnlyPrivate: false,
      tags: [],
      selectedTags: [],
      suggestionsShown: false,
      page: 1,
      defaultStatuses: [],
      showLoading: true,
    };
  },
  directives: { infiniteScroll },
  components: {
    Post,
    noPostsIcon,
    SelectOneDropdown,
    SelectMultipleDropdown,
  },
  async created() {
    await Rails.ajax({
      url: `/boards/${this.postBoard.id}/all_board_posts/`,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        this.posts = Array.from(JSON.parse(data.posts));
        if (this.posts.length > 0) {
          this.currentUserVotes = data.current_user_votes;
          this.posts.forEach((post) => {
            post.current_user_voted_for = this.currentUserVotes.includes(
              post.id
            );
          });
        } else {
          this.noPosts = true;
        }
        if (this.postBoard.hide_not_set_status && !this.currentProjectAdmin) {
          this.posts = this.posts.filter(
            (post) => post.status.name !== "Not set"
          );
        }
        this.initializeTotalUpvotes(this.posts);
        this.originalPosts = this.posts;
        this.showLoading = false;
      },
      error: (data, status) => {
        console.log(data);
      },
    });

    // Get default status objects of below mentioned statuses in an array
    this.defaultStatuses = this.postStatus.filter((status) => {
      return ["Done"].includes(status.name);
    });
    this.defaultStatuses.unshift({
      name: "Not done",
      human_name: "Not done",
      color: "#828282",
    });
    this.defaultStatuses.unshift({
      name: "All",
      human_name: "All Statuses",
      color: "#828282",
    });
  },
  mounted: function () {
    var all_tags = this.allTags
      ? this.allTags.map((tag) => tag.name.trim())
      : [];
    this.tags = [...new Set(all_tags)];
    var ctx = this;
    EventBus.$on("showLoader", function () {
      ctx.showLoading = true;
    });
    EventBus.$on("showSuggestions", function (data) {
      if (data.length > 0) {
        ctx.suggestionsShown = true;
        ctx.posts = data;
        ctx.initializeTotalUpvotes(ctx.posts);
        ctx.showLoading = false;
        ctx.noPosts = false;
      } else {
        ctx.suggestionsShown = false;
        ctx.posts = ctx.originalPosts;
        ctx.noPosts = !!!ctx.posts.length;
        ctx.showLoading = false;
      }
    });
    EventBus.$on("hideSuggestions", function () {
      ctx.suggestionsShown = false;
      ctx.posts = ctx.originalPosts;
      ctx.noPosts = !!!ctx.posts.length;
    });
    EventBus.$on("postVotes", (data) => {
      let upvotedPost = this.posts.find((post) => post.id === data.id);
      upvotedPost.total_upvotes = data.total;
    });

    // applying URL params
    var current_url = new URL(window.location.href);
    var sort = current_url.searchParams.get("sort");
    var filter = current_url.searchParams.get("filter");
    var tags = current_url.searchParams.get("tags");
    if (filter) {
      this.filter = filter;
    }

    if (sort) {
      this.sort = sort;
    }

    if (tags) {
      // add tags from URL to selectedTags
      this.selectedTags = tags.split(",");
    }

    this.initializeTotalUpvotes();
  },
  computed: {
    sortOptions() {
      return [
        { name: "trend_score", human_name: this.$t("Trending") },
        { name: "total_upvotes", human_name: this.$t("Most votes") },
        { name: "created_at", human_name: this.$t("Recent") },
        { name: "comments_count", human_name: this.$t("Most discussed") },
      ];
    },
    filteredPosts: function () {
      if (this.filter !== "") {
        var filtered = [];
        if (this.filter === "Not done") {
          filtered = this.posts.filter((post) => {
            return (
              post.status.act_as_done !== true && post.status.name !== "Not now"
            );
          });
        } else if (this.filter === "All") {
          return this.posts;
        } else {
          filtered = this.posts.filter((post) => {
            return post.status.name === this.filter;
          });
        }
        return this.filterTaggedPosts(filtered);
      } else {
        return this.posts;
      }
    },
    statusComp() {
      const filterAllStatus = [];
      this.statusIds.forEach((id) => {
        const result = this.postStatus.find(
          (status) => status.id === id && !this.defaultStatuses.includes(status)
        );
        if (result) {
          filterAllStatus.push(result);
        }
      });
      const notSet = this.postStatus.filter(
        (status) => status.name === this.statusName.not_set
      );
      notSet[0].human_name = "Not set";

      if (this.postBoard.hide_not_set_status) {
        if (this.currentProjectAdmin)
          this.defaultStatuses.splice(2, 0, notSet[0]);
      } else {
        this.defaultStatuses.splice(2, 0, notSet[0]);
      }

      return filterAllStatus.map((status) => {
        status.human_name = status.name;
        return status;
      });
    },
    sortedAndFilteredPosts: function () {
      if (this.suggestionsShown === false && !this.nofilter) {
        var filtered = this.filteredPosts;
        return reverse(sortBy(filtered, this.sort));
      } else {
        return this.posts;
      }
    },

    filteredMinePosts: function () {
      if (this.showOnlyMine) {
        return this.sortedAndFilteredPosts.filter((post) => {
          return (
            post.user_id === this.currentUser.id || post.current_user_voted_for
          );
        });
      } else {
        return this.sortedAndFilteredPosts;
      }
    },

    paginatedPosts: function () {
      let perPage = 10;
      return this.filteredPrivatePosts(this.filteredMinePosts).slice(
        0,
        this.page * perPage
      );
    },

    currentFilter() {
      return (
        this.statusComp.find((value) => value.name === this.filter) ||
        this.defaultStatuses.find((value) => value.name === this.filter)
      );
    },
  },
  methods: {
    parameterize: function (input) {
      return input
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
    },

    filterTaggedPosts: function (posts) {
      var tagged = [];

      if (this.selectedTags.includes("untagged")) {
        tagged = posts.filter((post) => {
          return post.tags.length === 0;
        });
      } else {
        tagged = posts.filter((post) => {
          return this.selectedTags.every((tagName) => {
            return post.tags.find((postTag) => {
              return (
                this.parameterize(postTag.name) == this.parameterize(tagName)
              );
            });
          });
        });
      }

      return tagged;
    },

    filteredPrivatePosts: function (posts) {
      if (this.showOnlyPrivate) {
        return posts.filter((post) => {
          return post.is_private;
        });
      } else {
        return posts;
      }
    },

    changeSort(value) {
      this.sort = value;
      var current_url = new URL(window.location);
      current_url.searchParams.set("sort", value);
      window.history.pushState(window.history.state, "", current_url);
    },
    changeFilter(value) {
      this.filter = value;
      var current_url = new URL(window.location);
      current_url.searchParams.set("filter", value);
      window.history.pushState(window.history.state, "", current_url);
    },

    loadMore: function () {
      this.page++;
    },

    updateSelectedTags(newTags) {
      this.selectedTags = newTags;
    },

    initializeTotalUpvotes(posts = this.posts) {
      posts.forEach((post) => {
        post["total_upvotes"] = post.cached_votes_up + post.manual_upvotes;
      });
    },
  },
};
</script>

<style scoped lang="scss">
body {
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-div .wrapper {
  margin-top: 23px;
  margin-left: 10px;
  padding: 10px;
  background: #fff;
  width: 360px;
  height: 640px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wrapper .wrapper-cell {
  display: flex;
  margin-bottom: 21px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.wrapper .wrapper-cell .image {
  width: 55px;
  height: 67px;
  border-radius: 3px;
  @extend .animated-background;
}

.wrapper .wrapper-cell .text {
  margin-left: 20px;
}

.wrapper .wrapper-cell .text .text-line-1 {
  position: absolute;
  width: 470px;
  height: 22px;
  border-radius: 3px;
  @extend .animated-background;
}

.wrapper .wrapper-cell .text .text-line-2 {
  position: absolute;
  width: 280px;
  height: 22px;
  border-radius: 3px;
  margin-top: 30px;
  @extend .animated-background;
}

@media only screen and (max-width: 767px) {
  .wrapper .wrapper-cell .text .text-line-1 {
    width: 320px;
  }
  .wrapper .wrapper-cell .text .text-line-2 {
    width: 210px;
  }
}

@media only screen and (max-width: 480px) {
  .wrapper .wrapper-cell .text .text-line-1 {
    width: 250px;
  }
  .wrapper .wrapper-cell .text .text-line-2 {
    width: 170px;
  }
}

@media only screen and (max-width: 400px) {
  .wrapper .wrapper-cell .text .text-line-1 {
    width: 220px;
  }
  .wrapper .wrapper-cell .text .text-line-2 {
    width: 145px;
  }
}
</style>
