<template>
  <dropdown :autoclose="true">
    <div
      slot="button"
      tabindex="1"
      class="border border-neutral-300 rounded-md bg-gradient-to-b from-white to-neutral-100 shadow-sm px-3 py-1 text-dblue-500 flex items-center focus:to-white focus:border-neutral-400"
      id="select-one-box"
    >
      <div class="mr-1 text-dblue-400" id="select-one-icon">
        <slot name="icon"></slot>
      </div>
      <!-- <span class="font-semibold">{{ name }}:&nbsp;</span> -->
      <span class="text-neutral-800">{{
        $t(selectedValue && selectedValue.human_name)
      }}</span>
      <svg
        class="w-4 h-4 ml-1 -mr-1"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div
      slot="items"
      class="flex flex-col py-2 w-56 border border-dblue-100 rounded-md hidden"
      id="select-one-list"
    >
      <div v-if="defaultStatus" class="mb-4 pb-4 border-b">
        <a
          href="#"
          v-for="(value, index) in defaultStatus"
          :key="index"
          class="px-4 py-1 flex items-center text-neutral-800 hover:no-underline hover:text-neutral-900 hover:bg-neutral-100"
          :class="{
            'font-bold': value.name === (selectedValue && selectedValue.name),
          }"
          @click.prevent="selectValue(value)"
        >
          <div
            v-if="value.color"
            class="w-2 h-2 rounded-full mr-2"
            :style="'background-color: ' + value.color"
          ></div>
          <span>{{ $t(value.human_name) }}</span>
        </a>
      </div>
      <a
        href="#"
        v-for="(value, index) in customStatuses"
        :key="index"
        class="px-4 py-1 flex items-center text-neutral-800 hover:no-underline hover:text-neutral-900 hover:bg-neutral-100"
        :class="{
          'font-bold': value.name === (selectedValue && selectedValue.name),
        }"
        @click.prevent="selectValue(value)"
      >
        <div
          v-if="value.color"
          class="w-2 h-2 rounded-full mr-2"
          :style="'background-color: ' + value.color"
        ></div>
        <span>{{ $t(value.human_name) }}</span>
      </a>
    </div>
  </dropdown>
</template>

<script>
export default {
  props: ["name", "values", "currentValue", "default-status", "isAdmin"],
  data() {
    return {
      customStatuses: [],
    };
  },

  mounted() {
    if (this.isAdmin) {
      this.customStatuses = this.values;
    } else {
      this.customStatuses = this.values.filter(
        (value) => value.name !== "Not now"
      );
    }
  },

  computed: {
    selectedValue() {
      return (
        this.values.find((value) => {
          return value.name === this.currentValue;
        }) ||
        this.defaultStatus.find((value) => value.name === this.currentValue)
      );
    },
  },
  methods: {
    selectValue(value) {
      this.$emit("valueChange", value.name);
    },
  },
};
</script>

<style></style>
