<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">Menu</h3>
    <p class="text-neutral-800 mb-4">
      Configure the links that are displayed in your top menu.
    </p>
    <form class="w-full" @submit.prevent="handleSubmit()" method="post">
      <div
        id="table-container"
        class="box-border bg-white border border-gray-300 border-solid rounded-md p-4"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="font-semibold text-base leading-5 text-gray-600 text-left pb-4"
              >
                Label
              </th>
              <th
                class="font-semibold text-base leading-5 text-gray-600 text-left pb-4 pl-4"
              >
                Link type
              </th>
              <th
                class="font-semibold text-base leading-5 text-gray-600 text-left pb-4 pl-4"
              >
                Link URL
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in projectMenus" :key="index">
              <td class="pb-4">
                <input
                  v-model="menuItems[`${item.id}`]['label']"
                  :id="`project_menu_items_attributes_${item.id}_label`"
                  type="text"
                  class="input"
                  :placeholder="item.link_type === 'boards' ? null : 'Label'"
                  :name="`project[menu_items_attributes][${item.id}][label]`"
                  :disabled="menuItems[`${item.id}`]['link_type'] === 'boards'"
                />
              </td>
              <td class="pb-4 pl-4">
                <div class="relative max-w-md">
                  <select
                    v-model="menuItems[`${item.id}`]['link_type']"
                    :id="`project_menu_items_attributes_${item.id}_link_type`"
                    class="input select"
                    :name="`project[menu_items_attributes][${item.id}][link_type]`"
                    @change="resetAndDisableFields($event, `${item.id}`)"
                  >
                    <option
                      v-for="option in linkTypes"
                      :key="option[0]"
                      :value="option[0]"
                      :selected="option[0] === item.link_type"
                      :disabled="(option[0] === 'roadmap' && roadmapEnabled == false) || (option[0] === 'changelog' && changelogEnabled == false)"
                    >
                      {{ option[1] }}
                    </option>
                  </select>
                  <svg
                    class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </td>
              <td class="pb-4 pl-4">
                <input
                  v-model="menuItems[`${item.id}`]['link_url']"
                  :id="`project_menu_items_attributes_${item.id}_link_url`"
                  type="text"
                  class="input"
                  :placeholder="
                    ['boards', 'changelog', 'roadmap'].includes(
                      menuItems[`${item.id}`]['link_type']
                    )
                      ? null
                      : 'https://'
                  "
                  :name="`project[menu_items_attributes][${item.link_url}][link_url]`"
                  :disabled="
                    ['boards', 'changelog', 'roadmap'].includes(
                      menuItems[`${item.id}`]['link_type']
                    )
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div 
      v-if="boards.length > 1"
      class="my-10">
      <h4 class="text-lg font-bold text-neutral-800">
        Board Order
      </h4>
      <p class="text-neutral-800 mb-4">
        Drag and drop the boards to change the order in which they appear in the
        top menu.
      </p>
      <div class="border border-gray-300 border-solid rounded-md p-4 max-w-[720px]">
        <draggable
          :list="boards"
          :animation="200"
          ghost-class="moving-card"
          class="w-full draggable-board-box"
          @start="drag=true"
          @end="drag=false"
        >
          <transition-group>
            <template v-for="board in boards">
              <div :key="board.id" class="flex items-center gap-4 p-3 focus:outline-none text-sm border border-neutral-300 rounded-md bg-gradient-to-b from-white to-neutral-100 shadow-sm text-dblue-500 focus:to-white focus:border-neutral-400">
                <span class="handle cursor-move">
                  <div id="edit-status-draggable-icon">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>
                  </div>
                </span>
                <div class="text-base leading-5 text-neutral-800">
                  {{ board.name }}
                </div>
              </div>
            </template>
          </transition-group>
        </draggable>
      </div>
      </div>
      <input
        type="submit"
        value="Save Menu"
        class="btn-main mt-5 rounded h-10 font-bold text-base leading-5"
      />
    </form>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "menus",
  props: ["project-menus", "roadmap-enabled", "changelog-enabled", "boards"],

  data() {
    return {
      linkTypes: [
        ["boards", "Boards"],
        ["changelog", "Changelog"],
        ["roadmap", "Roadmap"],
        ["custom_link", "Custom link"],
      ],
      menuItems: {},
    };
  },
  created() { 
    this.menuItems = Object.fromEntries(
      this.projectMenus.map((menu) => [
        menu.id,
        {
          label: menu.label,
          link_type: menu.link_type,
          link_url: menu.link_url,
        },
      ])
    );
    this.boards.sort((a, b) => {
      if (a.order_number === null && b.order_number === null) {
        return 0;
      }
      if (a.order_number === null) {
        return 1;
      }
      if (b.order_number === null) {
        return -1;
      }
      return a.order_number - b.order_number;
    });
  },
  components: {
    draggable,
  },
  methods: {
    resetAndDisableFields(event, id) {
      var val = event.target.value;
      if (val == 'boards') {
        this.menuItems[id].label = " ";
        this.menuItems[id].link_url = " ";
      }
      else if (val == 'roadmap' || val == 'changelog') {
        if (val == 'roadmap') {
          this.menuItems[id].label = "Roadmap";
          this.menuItems[id].link_url = " ";
        }
        else {
          this.menuItems[id].label = "Changelog";
          this.menuItems[id].link_url = " ";
        }
      }
    },
    handleSubmit() {
      this.sortingBoards();

      let formData = new FormData();
      formData.append("menu_items", JSON.stringify(this.menuItems));

      Rails.ajax({
        url: "/projects/project_menus",
        type: "PATCH",
        data: formData,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {},
        error: (data, status) => {},
      });
    },
    sortingBoards() {
      let boardIds = this.boards.map((board) => board.id);
      let params = new FormData();
      params.append("board_ids", boardIds);

      Rails.ajax({
        url: "/projects/sort_boards",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {},
        error: (data, status) => {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#table-container {
  width: 100%;
  max-width: 720px;
  tr:last-child td {
    padding-bottom: 0px;
  }
  td {
    width: 220px;
  }
  td:nth-child(2),
  :nth-child(3) {
    width: 240px;
  }
  input:disabled {
    background-color: #f3f3f3;
  }
}

</style>
