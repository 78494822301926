<template>
  <div class="bg-white rounded-lg border col col_roadmap" :class="'roadmap-column-status-'+status.id">
    <h5
      class="text-center py-4 text-dblue-500 capitalize col-head relative"
    >
      <span class="flex items-center justify-center font-medium" id="roadmap-column-name">
        {{ this.status.name }}
        <span class="roadmap-column-entries">&nbsp;({{ filteredPosts.length }}) </span>
      </span>
    </h5>
    <div
      class="col-line"
      :style="{'background-color': status.color}"
      id="roadmap-column-line-color"
    >
    </div>

    <div
      class="p-5 pb-6 text-neutral-600 animate-pulse col__inside"
      v-if="this.loading"
    >
    <div class="flex items-center">
      <div class="w-12 h-16 mr-2 bg-neutral-200 rounded"></div>
      <div class="flex flex-col w-full">
        <div class="w-4/5 h-6 rounded bg-neutral-200"></div>
        <div class="w-1/2 h-4 mt-2 rounded bg-neutral-200"></div>
      </div>
    </div>
    </div>

    <div class="" v-else>
      <div id="all-posts" class="relative">
          <div 
          v-if="filteredPosts.length === 0"
          class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <div>
                <div class="relative w-16 h-16 flex items-center justify-center mb-4 mx-auto">
                  <div class="w-16 h-16 z-0 absolute bg-neutral-100 rounded-full top-0 left-0" />
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="40px" height="40px" class="z-10 relative opacity-20"><path d="M 8.90625 8 C 8.515625 8.042969 8.183594 8.3125 8.0625 8.6875 L 2.15625 26.4375 C 2.144531 26.46875 2.132813 26.5 2.125 26.53125 L 2.09375 26.5625 C 2.082031 26.582031 2.070313 26.605469 2.0625 26.625 L 2.0625 26.6875 C 2.050781 26.699219 2.042969 26.707031 2.03125 26.71875 C 2.03125 26.738281 2.03125 26.761719 2.03125 26.78125 C 2.019531 26.792969 2.011719 26.800781 2 26.8125 C 2 26.832031 2 26.855469 2 26.875 C 2 26.886719 2 26.894531 2 26.90625 C 1.945313 27.089844 1.945313 27.285156 2 27.46875 L 2 42 C 2 42.550781 2.449219 43 3 43 L 47 43 C 47.550781 43 48 42.550781 48 42 L 48 27.40625 C 48.070313 27.179688 48.058594 26.9375 47.96875 26.71875 C 47.957031 26.707031 47.949219 26.699219 47.9375 26.6875 L 47.9375 26.65625 C 47.917969 26.613281 47.898438 26.570313 47.875 26.53125 L 41.9375 8.6875 C 41.804688 8.28125 41.425781 8.003906 41 8 L 9 8 C 8.96875 8 8.9375 8 8.90625 8 Z M 9.71875 10 L 40.28125 10 L 45.625 26 L 32 26 C 31.449219 26 31 26.449219 31 27 C 31 30.324219 28.324219 33 25 33 C 21.675781 33 19 30.324219 19 27 C 19 26.449219 18.550781 26 18 26 L 4.375 26 Z M 4 28 L 17.1875 28 C 17.703125 31.914063 20.949219 35 25 35 C 29.050781 35 32.296875 31.914063 32.8125 28 L 46 28 L 46 41 L 4 41 Z"/></svg>
                </div>
                <div class="text-neutral-700 text-center">
                  <div class="text-sm">No posts yet</div>
                </div>
              </div>
            </div>
        <transition-group name="list" tag="div" class="col__inside">
          <div
            style="overflow-wrap: anywhere;"
            class="flex items-start m-4 mb-5 break-words"
            is="post"
            v-for="post in filteredPosts"
            :originalPost="post"
            :currentUser="currentUser"
            :current-project-admin="currentProjectAdmin"
            :key="post.id"
            :allow-anonymous="allowAnonymous"
            :status-name="statusName"
            :id="'roadmap-column-post-'+post.id"
            :class="'roadmap-column-post-'+post.id"
          ></div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import Post from "../roadmap/post.vue";

import reverse from "lodash/reverse";
import EventBus from "event_bus";

export default {
  props: ["current-user", "original-posts", "status", "loading", "allow-anonymous", "project-id", "status-name", "current-project-admin"],
  data: function () {
    return {
      filter: this.status,
    };
  },
  components: {
    Post,
  },
  computed: {
    filteredPosts: function () {
      return this.originalPosts.filter((post) => {
        return post.status.name === this.filter.name;
      });
    },

  },
};
</script>

<style scoped>
  .col-line {
    height: 2px;
    width: 100%
  }
  .img-width {
    max-width: 80%;
  }
  .col__inside {
    height: 60vh;
    overflow: auto;
  }

  @media only screen and (min-width: 200px) and (max-width: 1023px){
    .col_roadmap {
      min-width: 100% !important;
      margin-bottom: 11px;
      /* min-height: 20rem !important; */
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1279px){
    .col_roadmap {
      min-width: 31.15% !important;
      /* min-height: 30rem !important; */
    }
  }
  @media only screen and (min-width: 1280px) and (max-width: 1535px){
    .col_roadmap {
      min-width: 31.6% !important;
      /* min-height: 30rem !important; */
    }
  }
  .col_roadmap {
    min-width: 31.90%;
    /* min-height: 45rem; */
  }
</style>
