<template>
  <div class="">
    <transition name="showUp">
      <div
        class="fixed flex inset-0 bg-black bg-opacity-25 z-40"
        v-if="value"
        @click.self="handleClose"
      ></div>
    </transition>
    <transition name="comeIn">
      <div
        class="fixed flex items-start justify-center inset-0 z-50 overflow-y-scroll"
        v-if="value"
        @click.self="handleClose"
      >
        <div class="modal bg-white rounded shadow-lg my-20">
          <div class="flex p-8 items-center">
            <h4 class="font-bold text-xl leading-6">{{ title }}</h4>
            <a
              href="#"
              class="text-neutral-700 hover:text-project-500 ml-auto"
              @click.prevent="handleClose"
              ><svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path></svg
            ></a>
          </div>
          <div class="px-8 pb-8 pt-5 border-t">
            <form
              enctype="multipart/form-data"
              @submit.prevent="createNewPost()"
              accept-charset="UTF-8"
              method="post"
              id="new-post-form"
            >
              <input name="utf8" type="hidden" value="✓" />

              <input
                type="hidden"
                name="authenticity_token"
                :value="this.form_token"
              />

              <div v-if="isAdmin">
                <AuthorSelect
                  :label="$t('Author Name')"
                  :options="authors"
                  :current-user="currentUser"
                  v-model="postAuthor"
                  :show-options="showOptions"
                  @showAuthorEmailField="showAuthorEmailField"
                  @hideAuthorEmailField="hideAuthorEmailField"
                  @addSelectedAuthor="addSelectedAuthor"
                  @removeSelectedAuthor="removeSelectedAuthor"
                  @showDropdown="showDropdown"
                />
              </div>

              <section v-if="authorEmailField">
                <div class="mt-3">
                  <label class="font-semibold text-sm leading-3 text-gray-700"
                    >{{ $t("Author email") }}
                  </label>
                  <input
                    id="author_email"
                    placeholder=""
                    v-model="authorEmail"
                    type="email"
                    name="post[email]"
                    class="input rounded mt-1"
                    autocomplete="off"
                    required
                  />
                </div>

                <div
                  class="mt-3 text-neutral-700 text-xs text-width flex flex-row"
                  v-if="!projectPrivate"
                >
                  <input
                    type="checkbox"
                    value="1"
                    name="post[notify_user]"
                    id="agree_checkbox"
                    v-model="agreeCheckbox"
                    style="height: 12px; margin-top: 2px"
                  />
                  <div class="ml-1">
                    {{
                      $t(
                        "Notify the user about updates and comments to this idea"
                      )
                    }}
                  </div>
                </div>
              </section>

              <div class="mt-3">
                <label class="font-semibold text-sm leading-3 text-gray-700"
                  >{{ $t("Title") }}
                </label>
                <input
                  id="post_title"
                  :placeholder="$t('Something short')"
                  v-model="newPostTitle"
                  autocomplete="off"
                  required="required"
                  type="text"
                  name="post[title]"
                  class="input rounded mt-1"
                />
              </div>

              <div class="mt-3">
                <label class="font-semibold text-sm leading-3 text-gray-700"
                  >{{ $t("Description") }}
                </label>
                <textarea
                  id="post_content"
                  :placeholder="$t('Write about your idea in more detail here')"
                  v-model="newPostContent"
                  rows="4"
                  name="post[content]"
                  class="input rounded mt-1"
                ></textarea>
              </div>

              <div v-if="error" class="text-red-700 pt-4">
                <p>{{ error }}</p>
              </div>

              <FileUpload
                name="post[attachments][]"
                class="mt-3 mb-4"
                :noun="$t('images')"
                upy-id="post-image"
              >
              </FileUpload>
              <div v-if="isAdmin">
                <label
                  for="board"
                  class="font-semibold text-sm leading-3 text-gray-700"
                  >{{ $t("Board") }}
                </label>
                <div class="relative flex-1">
                  <select
                    id="board"
                    name="post[board]"
                    v-model="selectedBoard"
                    class="select capitalize mt-1"
                  >
                    <option
                      v-for="option in allBoards"
                      :key="option.board_id"
                      :value="option.board_id"
                      class="capitalize"
                    >
                      {{ option.board_name }}
                    </option>
                  </select>
                  <svg
                    class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>

              <div class="mt-3" v-if="isAdmin">
                <label
                  for="status"
                  class="font-semibold text-sm leading-3 text-gray-700"
                  >{{ $t("Status") }}
                </label>
                <div class="relative flex-1">
                  <select
                    id="status"
                    name="post[status_id]"
                    v-model="selectedStatus"
                    class="select capitalize mt-1"
                  >
                    <option
                      v-for="status in allStatuses"
                      :key="status.id"
                      :value="status.id"
                      class="capitalize"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                  <svg
                    class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>

              <input
                v-model="user_ip_address"
                type="hidden"
                name="post[user_ip_address]"
              />

              <div v-if="!userSignedIn">
                <div class="mt-3" id="post-name">
                  <label class="label">{{ $t("Name") }}</label>
                  <input
                    id="name"
                    :placeholder="$t('Your name')"
                    v-model="name"
                    autocomplete="off"
                    required="required"
                    type="text"
                    name="post[name]"
                    class="input"
                  />
                </div>
                <div class="mt-3" id="post-email">
                  <label class="label">{{ $t("Email") }}</label>
                  <input
                    id="email"
                    :placeholder="$t('Your email address')"
                    v-model="email"
                    autocomplete="off"
                    required="required"
                    type="email"
                    name="post[email]"
                    class="input"
                  />
                </div>

                <div
                  class="mt-3 text-neutral-700 text-xs text-width flex flex-row"
                  id="post-agree"
                >
                  <input name="user[agree_checkbox]" type="hidden" value="0" />
                  <input
                    required="required"
                    type="checkbox"
                    value="1"
                    name="user[agree_checkbox]"
                    id="user_agree_checkbox"
                    v-model="agree_checkbox"
                    style="height: 12px; margin-top: 2px"
                  />
                  <div class="ml-1">
                    <span>{{ $t("I agree with") }} FeedBear's</span>
                    <a
                      href="https://www.feedbear.com/privacy-policy"
                      title="Privacy Policy"
                      target="_blank"
                      class="text-neutral-600 underline hover:no-underline hover:text-project-500"
                      >{{ $t("Privacy Policy") }}</a
                    >
                    {{ $t("and") }}
                    <a
                      href="https://www.feedbear.com/terms"
                      title="Terms of Service"
                      target="_blank"
                      class="text-neutral-600 underline hover:no-underline hover:text-project-500"
                      >{{ $t("Terms and Conditions") }}</a
                    >
                  </div>
                </div>
              </div>
              <input
                :value="new Date()"
                type="hidden"
                name="post[agreed_with_privacy_policy]"
                id="user_agreed_with_privacy_policy"
              />

              <div class="mt-3 flex justify-end">
                <button
                  id="roadmap-idea-submit"
                  name="button"
                  type="submit"
                  class="flex flex-row justify-evenly bg-project-500 text-white font-semibold px-4 py-2 w-40 text-center block rounded border border-project-500 hover:bg-white hover:no-underline hover:text-project-500 hover:shadow-lg"
                >
                  <span>{{ $t("Add idea") }}</span>
                  <div v-if="loading" class="flex items-center h-full">
                    <loader :border-color="loaderColor" />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FileUpload from "../components/file_upload";
import AuthorSelect from "./custom_select";
import loader from "../projects/spinner";
import { mapGetters, mapActions } from "vuex";

export default {
  props: [
    "title",
    "value",
    "form_action",
    "form_token",
    "allStatuses",
    "allBoards",
    "current-user",
    "idea-creation-url",
    "project-color",
    "project-private",
    "isAdmin",
    "user-signed-in",
    "currentProjectUsersUrl",
  ],
  data() {
    return {
      postAuthor: "",
      authorEmail: "",
      newPostTitle: "",
      newPostContent: "",
      selectedAuthor: this.currentUser.id,
      selectedStatus: this.allStatuses[0].id,
      selectedBoard: this.allBoards[0].board_id,
      agreeCheckbox: false,
      authorEmailField: false,
      user_ip_address: "",
      loading: false,
      loaderColor: "#fff",
      showOptions: true,
      name: "",
      email: "",
      agree_checkbox: false,
      error: "",
    };
  },
  components: {
    FileUpload,
    AuthorSelect,
    loader,
  },
  created() {
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.user_ip_address = ip;
      });
  },
  async mounted() {
    if (this.userSignedIn && this.isAdmin) {
      await this.$store.dispatch(
        "getcurrentProjectUsers",
        this.currentProjectUsersUrl
      );
    }
    var submitButton = document.querySelector("#roadmap-idea-submit");
    submitButton.addEventListener(
      "mouseover",
      (event) => (this.loaderColor = this.projectColor)
    );
    submitButton.addEventListener(
      "mouseout",
      (event) => (this.loaderColor = "#fff")
    );

    document.body.classList.add("overflow-hidden");
  },
  computed: {
    ...mapGetters(["allUsers"]),
    authors() {
      return this.allUsers;
    },
  },
  methods: {
    ...mapActions(["getcurrentProjectUsers"]),
    handleClose() {
      document.body.classList.remove("overflow-hidden");
      this.resetAuthorFields();
      this.$emit("input", false);
    },

    resetAuthorFields() {
      this.authorEmailField = false;
      this.postAuthor = "";
    },

    resetPostFields() {
      this.newPostTitle = "";
      this.newPostContent = "";
    },

    showAuthorEmailField(postAuthor) {
      this.postAuthor = postAuthor;
      this.authorEmailField = true;
    },

    showDropdown(value) {
      this.showOptions = value;
    },

    hideAuthorEmailField() {
      this.authorEmailField = false;
      this.postAuthor = "";
    },

    addSelectedAuthor(authorId) {
      this.selectedAuthor = authorId;
      this.postAuthor = "";
    },

    removeSelectedAuthor() {
      this.selectedAuthor = null;
    },

    createNewPost() {
      this.loading = true;

      var formData = new FormData(document.getElementById("new-post-form"));

      if (this.currentUser) {
        if (this.selectedAuthor) {
          formData.append("post[author]", this.selectedAuthor);
        } else {
          formData.append("post[name]", this.postAuthor);
          if (!this.agreeCheckbox) formData.append("dont_notify_author", true);
        }
      }

      Rails.ajax({
        url: this.ideaCreationUrl,
        type: "POST",
        data: formData,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.$emit("updateRoadmap", {
            message: data.message,
            url: data.idea_url,
          });
          this.loading = false;
          this.resetPostFields();
          this.resetAuthorFields();
          this.handleClose();
        },
        error: (data, status) => {
          this.loading = false;
          this.error = data.error;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: 520px;
}
.showUp-enter-active,
.showUp-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.showUp-enter,
.showUp-leave-to {
  opacity: 0;
}

.showUp-leave,
.showUp-enter-to {
  opacity: 1;
}

.comeIn-enter-active,
.comeIn-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.comeIn-enter,
.comeIn-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.comeIn-leave,
.comeIn-enter-to {
  opacity: 1;
  transform: scale(1);
}
</style>
