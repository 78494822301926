<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">Feedback</h3>
    <h5 class="font-bold text-neutral-800 mt-3">Voting on feedback ideas</h5>
    <p class="text-neutral-800 mt-1">
      By default, we allow anyone to upvote on feedback ideas, whether they've
      previously registered or not. You can change that and require users to
      have an account or register to vote.
    </p>

    <div class="toggle-wrapper mt-4 text-neutral-800 items-start flex">
      <input
        type="checkbox"
        class="checkbox"
        @change="changeAllowVote()"
        v-model="allow_anonymous_vote"
      />
      <span class="ml-2"
        >Require users to log in or sign up to vote on an idea</span
      >
    </div>

    <h5 class="font-bold text-neutral-800 mt-6">Posting anonymous ideas</h5>
    <p class="text-neutral-800 mt-1">
      By default, we require users to have an account or register to submit
      feedback. You can change that and allow anyone to submit feedback ideas,
      whether they've previously registered or not.
    </p>

    <div class="toggle-wrapper mt-4 text-neutral-800 items-start flex">
      <input
        type="checkbox"
        class="checkbox"
        @change="changeAllowAnonymous()"
        v-model="allow_anonymous_posts"
      />
      <span class="ml-2"
        >Require users to log in or sign up to post an idea</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    allowAnonymousVote: String,
    allowAnonymousPosts: String,
    projectId: String,
    token: String,
  },
  data: function () {
    return {
      allow_anonymous_vote: !JSON.parse(this.allowAnonymousVote),
      allow_anonymous_posts: !JSON.parse(this.allowAnonymousPosts),
      project_id: this.projectId,
      form_token: this.token,
    };
  },
  methods: {
    changeAllowVote(event) {
      this.isAllowAnonymousToggleLoading = true;
      let data = new FormData();
      data.append("allow_anonymously_vote", !this.allow_anonymous_vote);
      data.append("id", this.project_id);
      Rails.ajax({
        url: "/allow_anonymous_vote",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isAllowAnonymousToggleLoading = false;
          this.$emit(
            "allowAnonymouslyVote",
            data.allow_anonymously_vote.toString()
          );
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowAnonymousToggleLoading = false;
        },
      });
    },

    changeAllowAnonymous(event) {
      this.isAllowAnonymousToggleLoading = true;
      let data = new FormData();
      data.append("allow_anonymous_posts", !this.allow_anonymous_posts);
      data.append("id", this.project_id);
      Rails.ajax({
        url: "/allow_anonymous_posts",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isAllowAnonymousToggleLoading = false;
          this.$emit(
            "allowAnonymousPosts",
            data.allow_anonymous_posts.toString()
          );
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowAnonymousToggleLoading = false;
        },
      });
    },
  },
};
</script>
<style scoped>
input[type="checkbox"] {
  min-width: 5mm;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 5mm;
  border: 0.1mm solid #00000052;
  border-radius: 5px;
  margin-top: 3px;
}

input[type="checkbox"]:checked:after {
  margin-left: 1.5mm;
  width: 1.5mm;
  margin-bottom: 1mm;
  height: 4mm;
  border: solid green;
  border-width: 0 1mm 1mm 0;
  transform: rotate(45deg);
  content: "";
  display: inline-block;
}
</style>
