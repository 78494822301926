<template>
  <div class="relative z-20">
    <transition name="fadeIn">
      <div
        class="drawer-backdrop"
        v-if="labelsOpen"
        @click.self="labelsOpen = false"
      ></div>
    </transition>
    <transition name="slideFromRight">
      <div class="drawer" v-if="labelsOpen">
        <div class="drawer__close">
          <a href="#" @click.prevent="labelsOpen = false" title="Close drawer">
            <simple-svg
              :filepath="closeIcon"
              :width="'20px'"
              :height="'20px'"
            />
          </a>
        </div>
        <div class="drawer__content">
          <h3 class="mt-2 font-bold text-xl text-neutral-800">
            Customize labels
          </h3>
          <p class="mt-2 text-neutral-800">
            Use labels to separate different kinds of updates.
          </p>
          <p class="mt-1 text-neutral-800">
            We created a default set for you, but feel free to change it according to your needs
          </p>

          <div class="labels">
            <template v-for="label in allLabels">
              <Label
                :original-label="label"
                :key="label.id"
                @destroyedLabel="removeLabel($event)"
                @updatedLabel="updateLabel($event)"
              ></Label>
            </template>
            <NewLabel @newLabel="addLabel($event)"></NewLabel>
          </div>
        </div>
      </div>
    </transition>

    <small>
      <a href="#" class="link--fade" @click.prevent="labelsOpen = !labelsOpen"
        >Customize labels</a
      >
    </small>
  </div>
</template>

<script>
import closeIcon from "../../images/icons/x.svg";

import Label from "./label";
import NewLabel from "./new_label";

export default {
  props: ["labels"],

  data: function() {
    return {
      allLabels: this.labels,
      labelsOpen: false,
      closeIcon
    };
  },

  mounted: function() {
    window.addEventListener("keydown", e => {
      if (e.key == "Escape") {
        this.labelsOpen = false;
      }
    });
  },

  components: {
    closeIcon,
    Label,
    NewLabel
  },

  methods: {
    addLabel(label) {
      this.$emit("createdLabel", label);
    },

    removeLabel(label) {
      this.$emit("destroyedLabel", label);
    },

    updateLabel(label) {
      this.$emit("updatedLabel", label);
    }
  }
};
</script>

<style scoped>
.slideFromRight-enter,
.slideFromRight-leave-to {
  opacity: 0;
  transform: translateX(10vh);
}

.slideFromRight-enter-to,
.slideFromRight-leave {
  opacity: 1;
  transform: translateX(0);
}

.slideFromRight-enter-active,
.slideFromRight-leave-active {
  transition: opacity, transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.fadeIn-enter-to,
.fadeIn-leave {
  opacity: 1;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 200ms ease-in;
}

.labels {
  margin-top: 3rem;
}

.simple-svg-wrapper {
  display: flex;
}
</style>
