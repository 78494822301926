<template>
  <div class="">
    <!-- Dialog -->
    <ChangelogDialog title="Let's add a changelog entry" v-model="this.showChangelogPostSuggestion">
      <form @submit.prevent="editDraft">
        <div class="flex flex-col justify-center ml-8 mr-8 mb-8">
          <p class="text-gray-500">FeedBear can generate a changelog draft for "{{this.post.title}}"</p>
          <transition name="fade" v-if="isLoading">
            <div class="flex flex-row justify-center rounded-sm bg-gray-100 mt-4">
              <Spinner class="ml-3" border-color="#6b7280" />
              <div class="m-2 mt-4 text-sm mb-4 text-gray-500 mt-4">Generating changelog entry with AI...</div>
            </div>
          </transition>
          <transition name="fade" v-if="!isLoading && this.changelogTitle">
            <div class="flex flex-col justify-center rounded-md mt-6 bg-black bg-opacity-5">
              <p class="text-gray-600 m-4 font-bold">{{this.changelogTitle}}</p>
              <div v-html="this.changelogBody" class="text-gray-500 text-sm m-4 -mt-2"></div>
            </div>
          </transition>
          <transition name="fade" v-if="!isLoading && isError">
            <div class="alert alert--bad rounded-md mt-6">
              <p class="ml-2 mr-2">{{'There was an error generating the text.'}} <a href="#" class="text-white underline" @click.prevent="getSuggestion()">{{'Please try again...'}}</a></p>
            </div>
          </transition>
          <div class="flex mt-3 justify-between">
            <a href="/edit?view=ai_settings" class="text-sm text-gray-600 mt-6">
              Edit AI Settings
            </a>
            <div class="flex flex-row justify-between">
              <button type="submit" class="btn-main text-sm flex justify-center rounded mt-4">
                Edit draft
              </button>
            </div>
          </div>
        </div>
      </form>
    </ChangelogDialog>
  </div>
</template>

<script>
import ChangelogDialog from "./changelog_dialog";
import Spinner from "../projects/spinner";

export default {
  props: ["post", "company-details", "show-changelog-post-suggestion"],
  data() {
    return {
      changelogTitle: "",
      changelogBody: "",
      isLoading: false,
      isError: false,
    };
  },
  components: {
    ChangelogDialog,
    Spinner,
  },
  computed:{
  },
  mounted() {
    this.isLoading = true;
    this.isError = false;
    let data = new FormData();
    data.append("post_id", this.post.id);
    Rails.ajax({
      url: `/changelog/suggest_entry`,
      type: "POST",
      data: data,
      dataType: "json",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        this.isLoading = false;
        this.changelogTitle = data.changelog_suggested_title;
        this.changelogBody = data.changelog_suggested_body;
      },
      error: (data, status) => {
        this.isLoading = false;
        this.isError = true;
        console.log(data);
      },
    });
  },
  methods: {
    getSuggestion() {
      this.isLoading = true;
      this.isError = false;
      let data = new FormData();
      data.append("post_id", this.post.id);
      Rails.ajax({
        url: `/changelog/suggest_entry`,
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isLoading = false;
          this.changelogTitle = data.changelog_suggested_title;
          this.changelogBody = data.changelog_suggested_body;
        },
        error: (data, status) => {
          this.isLoading = false;
          this.isError = true;
          console.log(data);
        },
      });
    },
    editDraft() {
      let data = new FormData();
      data.append("changelog_entry[title]", this.changelogTitle);
      data.append("changelog_entry[body]", this.changelogBody);
      Rails.ajax({
        url: '/changelog/suggestion/create',
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          window.location.href = `/updates/${data.id}/edit`;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    disableChangelogSuggestions() {
      const data = {post_id: this.postId}
      Rails.ajax({
        url: "/changelog/suggestion/disable",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
        },
        success: (data) => {
          this.show = false;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.iconColor:hover svg{
  fill: white;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
