<template>
  <div class="">
    <a
      href="#"
      class="text-neutral-800 flex justify-start items-center focus:shadow-none hover:no-underline hover:text-project-500 transition duration-150 ease-in-out"
      @click.prevent="isOpen = !isOpen"
    >
      <svg
        class="w-5 h-5 mr-2 transition-transform duration-150 ease-in-out"
        :class="{ 'transform rotate-90': isOpen }"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <h3 class="font-medium">{{ title }}</h3>
    </a>

    <transition name="show">
      <slot name="content" v-if="isOpen"></slot>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style scoped>
.show-enter-active,
.show-leave-active {
  transition: transform 200ms cubic-bezier(0.075, 0.82, 0.165, 1),
    opacity 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translate(0, -10px);
}
</style>
