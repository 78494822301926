<template>
  <div
    class="mt-4 md:pl-4 pt-2 pb-2"
    :class="[
      comment.pin ? 'bg-neutral-100 rounded-lg pt-4 pb-4' : '',
      parentCommentId !== 0 ? 'pl-4' : 'pl-2.5',
    ]"
  >
    <div class="flex items-center" :class="{ '': parentCommentId !== 0 }">
      <div v-if="currentProjectAdmin">
        <tippy
          interactive
          :animate-fill="false"
          placement="bottom-start"
          theme="light"
          animation="fade"
          trigger="mouseenter"
        >
          <template v-slot:trigger>
            <avatar
              :class="{ 'comment__avatar--admin': comment.by_project_admin }"
              class="avatar shadow-md mr-4 border-2 border-white cursor-pointer"
              :size="36"
              :src="comment.user.avatar"
              :username="comment.user.name"
            ></avatar>
          </template>
          <tooltip-tippy :user="comment.user"></tooltip-tippy>
        </tippy>
      </div>
      <avatar
        v-else
        :class="{ 'comment__avatar--admin': comment.by_project_admin }"
        class="avatar shadow-md mr-4 border-2 border-white"
        :size="36"
        :src="comment.user.avatar"
        :username="comment.user.name"
      ></avatar>
      <div class="flex flex-col" id="comment-user-name">
        <div v-if="currentProjectAdmin">
          <tippy
            interactive
            :animate-fill="false"
            placement="bottom-start"
            theme="light"
            animation="fade"
            trigger="mouseenter"
          >
            <template v-slot:trigger>
              <h5
                class="text-neutral-800 font-semibold text-sm leading-snug cursor-pointer"
              >
                {{ comment.user.name }}

                <span
                  v-if="comment.by_project_admin"
                  class="text-gray-500 font-normal"
                  >({{ projectName }})</span
                >
              </h5>
            </template>
            <tooltip-tippy :user="comment.user"></tooltip-tippy>
          </tippy>
        </div>
        <h5 class="text-neutral-800 font-semibold text-sm leading-snug" v-else>
          {{ comment.user.name }}
        </h5>
        <span class="text-sm text-neutral-600 leading-snug">
          {{
            formatDistance(new Date(comment.created_at), new Date(), {
              locale: datefnLocales[getLocale],
              addSuffix: true,
            })
          }}
        </span>
      </div>
    </div>
    <div v-if="edit_input_show">
      <comment-input
        class="mt-3 editComment edit-comment-input-container"
        :post-id="postId"
        :board-slug="boardSlug"
        :current-user="currentUser"
        :discussion-users="discussionUsers"
        :edit-comment="true"
        :comment-data="comment"
        @editHide="editHide"
        @updateCommentData="updateCommentData"
        :current-project-admin="currentProjectAdmin"
      ></comment-input>
    </div>
    <p
      class="mt-3 whitespace-pre-wrap break-words offset"
      :class="{ '': parentCommentId !== 0 }"
      v-html="
        $options.filters.highlightMentions(
          comment.body
            .replace(/\r\n|\\r\n|\\n/, '\n')
            .replace(/\\r/, '\r')
            .replace('${', '$ {')
        )
      "
      v-linkified:options="linkifyOpts"
      v-else
    ></p>

    <div
      class="flex items-center flex-wrap mt-2 offset"
      :class="{ '': parentCommentId !== 0 }"
      id="child-comment-attachment"
    >
      <a
        v-if="!edit_input_show"
        :href="file.url"
        target="_blank"
        v-for="(file, index) in comment.attached_files"
        :key="index"
        class="w-20 h-20 mr-2 shadow-lg border border-neutral-200 rounded-md transform hover:scale-105 hover:shadow-xl transition duration-150 ease-in-out"
      >
        <img
          :src="file.preview_url"
          class="object-cover h-20 w-full rounded-md"
          alt
        />
      </a>
    </div>

    <div
      class="flex mt-3 offset"
      :class="{ '': parentCommentId !== 0 }"
      id="comment-reply"
    >
      <a
        @click.prevent="showInputBox()"
        href="#"
        class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider mr-2 hover:no-underline hover:text-project-500 comment-feature"
      >
        <svg
          class="w-4 h-4 mr-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span>{{ $t("Reply") }}</span>
      </a>
      <a
        @click.prevent="showDeleteDialog(comment)"
        href="#"
        class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider mr-2 hover:no-underline hover:text-project-500 comment-feature"
        v-if="
          currentProjectAdmin ||
          (currentUser && currentUser.id == comment.user.id)
        "
      >
        <simple-svg class="" :filepath="trashIcon" width="16px" height="16px" />
        <span>{{ $t("Delete") }}</span>
      </a>
      <a
        @click.prevent="editShow()"
        href="#"
        class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider mr-2 hover:no-underline hover:text-project-500 comment-feature"
        v-if="
          currentProjectAdmin ||
          (currentUser && currentUser.id == comment.user.id)
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          width="16px"
          height="16px"
          class="mr-1"
        >
          <path
            fill="currentColor"
            d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z"
          ></path>
        </svg>
        <span>{{ $t("Edit") }}</span>
      </a>
      <div class="hide_pin" id="hide-pin">
        <a
          @click.prevent="pinComment(comment)"
          href="#"
          class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider mr-2 hover:no-underline hover:text-project-500 comment-feature"
          v-if="currentProjectAdmin && showPinButton"
        >
          <svg
            class="w-4 h-4 mr-0.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H14C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3H3Z M3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H8C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7H3Z M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H7C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11H3Z M13 16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16L15 10.4142L16.2929 11.7071C16.6834 12.0976 17.3166 12.0976 17.7071 11.7071C18.0976 11.3166 18.0976 10.6834 17.7071 10.2929L14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7C13.7348 7 13.4804 7.10536 13.2929 7.29289L10.2929 10.2929C9.90237 10.6834 9.90237 11.3166 10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071L13 10.4142L13 16Z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="showPinTop">{{ $t("Pin to Top") }}</span>
          <span class="showPin">Pin</span>
        </a>

        <a
          @click.prevent="pinComment(comment)"
          href="#"
          class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider mr-2 hover:no-underline hover:text-project-500 comment-feature"
          v-if="currentProjectAdmin && comment.pin"
        >
          <svg
            class="w-4 h-4 mr-0.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5H14C14.5523 5 15 4.55228 15 4C15 3.44772 14.5523 3 14 3H3Z M3 7C2.44772 7 2 7.44772 2 8C2 8.55228 2.44772 9 3 9H10C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7H3Z M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H7C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11H3Z M15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44771 13 8L13 13.5858L11.7071 12.2929C11.3166 11.9024 10.6834 11.9024 10.2929 12.2929C9.90237 12.6834 9.90237 13.3166 10.2929 13.7071L13.2929 16.7071C13.4804 16.8946 13.7348 17 14 17C14.2652 17 14.5196 16.8946 14.7071 16.7071L17.7071 13.7071C18.0976 13.3166 18.0976 12.6834 17.7071 12.2929C17.3166 11.9024 16.6834 11.9024 16.2929 12.2929L15 13.5858L15 8Z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>Unpin</span>
        </a>
      </div>
    </div>

    <div class v-if="inputBoxShown">
      <div class="mt-4" :class="{ offset: parentCommentId === 0 }">
        <comment-input
          :post-id="postId"
          :board-slug="boardSlug"
          :comment-id="comment.id"
          :parent-comment-id="parentCommentId"
          :current-user="currentUser"
          :discussion-users="discussionUsers"
          ref="messageArea"
          @newNestedComment="hideInputBox()"
          :id="'reply-comment-input-container' + comment.id"
          :current-project-admin="currentProjectAdmin"
        ></comment-input>
      </div>
    </div>
    <dialog-model
      v-if="confirm_delete"
      :title="$t('Delete Comment')"
      :button-text="$t('Delete')"
      :message="$t('Are you sure you want to delete your comment?')"
      @done="commentDelete"
      @cancel="commentCancel"
    ></dialog-model>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import { formatDistance } from "date-fns";
import CommentInput from "./comment_input.vue";
import EventBus from "event_bus";
import trashIcon from "images/trash.svg";
import { linkifyOpts } from "./variables";
import datefnLocales from "./locales/datefnlocales";

export default {
  props: [
    "original-comment",
    "discussion-users",
    "post-id",
    "current-user",
    "current-project-admin",
    "parent-comment-id",
    "board-slug",
    "showPin",
    "nestedshowPin",
    "projectName",
  ],

  data: function () {
    return {
      inputBoxShown: false,
      comment: this.originalComment,
      linkifyOpts,
      trashIcon,
      formatDistance,
      screenWidth: null,
      edit_input_show: false,
      confirm_delete: false,
      commentData: "",
      datefnLocales,
    };
  },
  components: {
    Avatar,
    CommentInput,
    trashIcon,
  },
  computed: {
    showPinButton() {
      if (this.showPin == undefined) return this.nestedshowPin;
      else return this.showPin;
    },
    getLocale() {
      return this.$root.$i18n.locale == "zh-CN"
        ? "zhCN"
        : this.$root.$i18n.locale;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
  },
  methods: {
    showInputBox: function () {
      if (this.currentUser) {
        this.inputBoxShown = true;
        this.$nextTick(() => {
          this.$refs.messageArea.$refs.messageAreaInput.focus();
          this.$refs.messageArea.$refs.messageAreaInput.innerHTML =
            '<span contenteditable="false" class="mention">@' +
            this.comment.user.name +
            "</span>&nbsp;";
          document.execCommand("selectAll", false, null);
          document.getSelection().collapseToEnd();
        });
      } else {
        EventBus.$emit("openRegModal");
      }
    },
    hideInputBox: function () {
      this.inputBoxShown = false;
    },
    showDeleteDialog: function (comment) {
      this.commentData = comment;
      this.confirm_delete = true;
    },
    commentDelete: function () {
      this.deleteComment(this.commentData);
    },
    commentCancel: function () {
      this.confirm_delete = false;
    },
    deleteComment: function (comment) {
      var delUrl =
        "/boards/" +
        this.boardSlug +
        "/posts/" +
        this.postId +
        "/comments/" +
        comment.id;
      Rails.ajax({
        url: delUrl,
        type: "DELETE",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log("Deleted", data);
          this.$emit("updateComments", data.comments);
          this.confirm_delete = false;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    editShow: function () {
      this.edit_input_show = true;
    },
    editHide: function (data) {
      this.edit_input_show = data;
    },
    updateCommentData: function (data) {
      this.comment = data;
      this.edit_input_show = false;
    },
    pinComment: function (comment) {
      var data = new FormData();
      comment.pin = !comment.pin;
      data.append("comment[pin]", comment.pin);

      var pinUrl =
        "/boards/" +
        this.boardSlug +
        "/posts/" +
        this.postId +
        "/comments/" +
        comment.id +
        "/pin_to_top";
      Rails.ajax({
        url: pinUrl,
        type: "PUT",
        data: data,
        dataType: "json",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          data && this.$emit("updateComments", data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
  },
  filters: {
    highlightMentions: function (text) {
      var pattern = /\B@[a-z0-9_-]+/gi;
      var replaced_text = text.replace(pattern, "<strong>$&</strong>");
      var pattern = /\@.+?\;/g;
      var replaced_text = text.replace(pattern, "<strong>$&</strong>");

      return replaced_text;
    },
  },
};
</script>

<style scoped>
.offset {
  margin-left: calc(36px + 1rem);
}
@media only screen and (max-width: 500px) and (min-width: 320px) {
  .offset {
    margin-left: 0px;
  }
}

.unpin_postion {
  justify-content: space-between;
  display: flex;
}

.showPin {
  display: none;
}
.editComment {
  padding-bottom: 30px;
}
.showPinTop {
  display: block;
}
@media only screen and (max-width: 367px) {
  .comment-feature {
    font-size: 0.65rem;
  }
}
@media only screen and (max-width: 500px) {
  .editComment {
    margin-left: 52px;
  }
}
@media only screen and (min-width: 500px) {
  .editComment {
    margin-left: 52px;
  }
}
@media only screen and (max-width: 600px) {
  .showPin {
    display: block;
  }

  .showPinTop {
    display: none;
  }
}
</style>
<style>
.tippy-tooltip.light-theme {
  box-shadow: 0 0 7px 4px rgb(154 161 177 / 15%),
    0 0px 2px 0px rgb(36 40 47 / 25%), 0 4px 4px -2px rgb(91 94 105 / 15%) !important;
}
.tippy-tooltip {
  padding: 0.5rem 0.5625rem !important;
}

.comment__avatar--admin {
  position: relative;
}

.comment__avatar--admin::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 16px;
  background: white;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='star' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path' fill='%23FFC857' fill-rule='nonzero' points='8 0.960938 7.539063 2.054688 5.953125 5.820313 0.992188 6.359375 4.703125 9.859375 3.648438 15.023438 8 12.375 12.347656 15.023438 11.296875 9.859375 15.003906 6.359375 10.046875 5.820313'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  border-radius: 50%;
  background-position: center;
  background-size: 85%;
  @apply shadow-md;
}
</style>
