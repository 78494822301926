<template>
  <section class="container grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8 mt-6">
    <div class="col-span-2 bg-white border p-6 rounded-lg mb-8 lg:mb-8">
      <PlanAlert v-if="this.current_plan === 'lite' && this.boards_length >= 1" title="Upgrade your plan to add more boards" :description="'The Lite plan is limited to a single board. Please upgrade your plan to add more.'" :link="'/edit?view=billing'" :click="'Click here to upgrade'" class="mb-6"/>   
      <div class="flex items-center justify-between">
        <h2
          class="font-semibold text-2xl text-neutral-800"
          v-if="this.originalBoard.name === null"
        >
          New feedback board
        </h2>
        <h2 class="font-semibold text-2xl text-neutral-800" v-else>
          Board settings
        </h2>
        <delete-board-dialog
          v-if="this.allBoards && this.allBoards.length >= 1"
          :original-board="this.originalBoard"
          :all-boards="this.allBoards"
        >
        </delete-board-dialog>
      </div>

      <div class="error-box" v-if="this.errors && this.errors.length > 0">
        <p>These errors prevented the board from saving:</p>
        <ul>
          <li v-for="error in this.errors" :key="error">{{ error }}</li>
        </ul>
      </div>

      <form
        :action="this.current_plan === 'lite' && this.boards_length >= 0 ? '#' : this.form_action"
        accept-charset="UTF-8"
        method="post"
        class="mt-4"
      >
        <input
          type="hidden"
          name="authenticity_token"
          :value="this.form_token"
        />
        <input name="_method" type="hidden" :value="this.form_method" />

        <h3 class="font-semibold text-lg text-neutral-800">General</h3>

        <div class="mt-2">
          <label class="label inline-block">Name</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500': this.name && this.name.length > 30,
            }"
            >{{ this.name ? this.name.length : "0" }}/30</small
          >
          <input
            id="board_name"
            type="text"
            name="board[name]"
            class="input"
            v-model="name"
            placeholder="i.e. Feedback and ideas"
            maxlength="30"
            autofocus
            required
          />
        </div>
        <div class="mt-4">
          <label class="label inline-block">Slug</label>
          <small class="text-neutral-700 text-sm float-right"
            >Use only a-z, 0-9 and "-"</small
          >
          <div class="flex items-center gap-1 input">
            <span class="flex-shrink-0 text-neutral-700 hidden md:block" id="span-id"
              >https://{{ this.project_subdomain }}.feedbear.com/boards/</span
            >
            <input
              id="board_slug"
              type="text"
              name="board[slug]"
              v-model="slug"
              class="flex-grow outline-none"
              placeholder="feedback-and-ideas"
              pattern="[a-z0-9\-]+"
              required
            />
          </div>
        </div>
        <div class="mt-4">
          <label class="label inline-block">Page heading</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500': this.page_heading && this.page_heading.length > 100,
            }"
            >{{ this.page_heading ? this.page_heading.length : "0" }}/100</small
          >
          <div class="flex items-center flex-wrap">
            <input
              id="page_heading"
              type="text"
              name="board[page_heading]"
              class="input"
              v-model="page_heading"
              maxlength="100"
            />
          </div>
        </div>

        <div class="mt-4">
          <label class="label inline-block">Page description</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500': this.page_description && this.page_description.length > 300,
            }"
            >{{ this.page_description ? this.page_description.length : "0" }}/300</small
          >
          <div class="flex items-center flex-wrap">
            <textarea
              id="page_description"
              type="text"
              name="board[page_description]"
              class="input"
              v-model="page_description"
              maxlength="300"
            />
          </div>
        </div>
     

        <h3 class="mt-8 text-lg font-semibold text-neutral-800">
          New idea form customization
        </h3>

        <div class="mt-2">
          <label class="label inline-block">Intro heading</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500':
                this.intro_heading && this.intro_heading.length > 30,
            }"
            >{{
              this.intro_heading ? this.intro_heading.length : "0"
            }}/30</small
          >
          <input
            id="board_intro_heading"
            type="text"
            name="board[intro_heading]"
            class="input"
            v-model="intro_heading"
            maxlength="30"
            required
          />
        </div>
        <div class="mt-4">
          <label class="label inline-block">Intro text</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500': this.intro_text && this.intro_text.length > 300,
            }"
            >{{ this.intro_text ? this.intro_text.length : "0" }}/300</small
          >
          <textarea
            id="board_intro_text"
            rows="3"
            name="board[intro_text]"
            class="input"
            v-model="intro_text"
            maxlength="300"
            required
          ></textarea>
        </div>
        <div class="mt-4">
          <label class="label inline-block">Form title</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500': this.form_title && this.form_title.length > 30,
            }"
            >{{ this.form_title ? this.form_title.length : "0" }}/30</small
          >
          <input
            id="board_form_title"
            type="text"
            name="board[form_title]"
            class="input"
            v-model="form_title"
            maxlength="30"
            required
          />
        </div>
        <div class="mt-4">
          <label class="label inline-block">Form title placeholder</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500':
                this.form_title_placeholder &&
                this.form_title_placeholder.length > 30,
            }"
            >{{
              this.form_title_placeholder
                ? this.form_title_placeholder.length
                : "0"
            }}/30</small
          >
          <input
            id="board_form_title_placeholder"
            type="text"
            name="board[form_title_placeholder]"
            class="input"
            v-model="form_title_placeholder"
            maxlength="30"
            required
          />
        </div>
        <div class="mt-4">
          <label class="label inline-block">Form body</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500':
                this.form_content && this.form_content.length > 30,
            }"
            >{{ this.form_content ? this.form_content.length : "0" }}/30</small
          >
          <input
            id="board_form_content"
            type="text"
            name="board[form_content]"
            class="input"
            v-model="form_content"
            maxlength="30"
            required
          />
        </div>
        <div class="mt-4">
          <label class="label inline-block">Form body placeholder</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500':
                this.form_content_placeholder &&
                this.form_content_placeholder.length > 60,
            }"
            >{{
              this.form_content_placeholder
                ? this.form_content_placeholder.length
                : "0"
            }}/60</small
          >
          <input
            id="board_form_content_placeholder"
            type="text"
            name="board[form_content_placeholder]"
            class="input"
            v-model="form_content_placeholder"
            maxlength="60"
            required
          />
        </div>

        <div class="mt-4">
          <label class="label inline-block">Form footer text</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500':
                this.form_footer_text && this.form_footer_text.length > 500,
            }"
            >{{
              this.form_footer_text ? this.form_footer_text.length : "0"
            }}</small
          >
          <textarea
            id="board_form_footer_text"
            name="board[form_footer_text]"
            class="input"
            v-model="form_footer_text"
          />
        </div>

        <div class="mt-4">
          <label class="label inline-block">Form button text</label>
          <small
            class="text-neutral-700 text-sm float-right"
            :class="{
              'text-red-500':
                this.form_button_text && this.form_button_text.length > 20,
            }"
            >{{
              this.form_button_text ? this.form_button_text.length : "0"
            }}/20</small
          >
          <input
            id="board_form_button_text"
            type="text"
            name="board[form_button_text]"
            class="input"
            v-model="form_button_text"
            maxlength="20"
            required
          />
        </div>


   <div class="mt-4">
          <h3 class="mt-8 text-lg font-semibold text-neutral-800">
           Default status for new ideas 
          </h3>
          <span>When new ideas are submitted, assign them this status</span>
          <div class="flex flex-row" v-if="selectedStatus" id="selected-board-status">
            <div class="relative flex-1 " id="edit-board-status-name">
              <select
                id="status"
                name="board[status_id]"
                v-model="selectedValue"
                class="select capitalize"
              >
                <option v-for="option in allStatuses" :key="option.id" :value="option.id" class="capitalize">{{option.name}}</option>
              </select>
              <svg
                class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="mt-8 toggle-wrapper mt-4 text-neutral-800 flex">
          <div>
            <input
              type="checkbox"
              class="checkbox"
              v-model="hide_not_set"
              name="board[hide_not_set_status]"
            />
          </div>
          <div class="ml-4">
            <b>Hide ideas with "Not Set" status from users</b><br/>
            <span>Only admins will be able to see and filter ideas marked with the "Not Set" status.</span>
          </div>
        </div>
        <div>
         <h3 class="mt-6 text-lg font-semibold text-neutral-800">
           Voting settings
          </h3>
          </div>

        <div class="toggle-wrapper mt-4 text-neutral-800 flex">
          <div>
            <input
              type="checkbox"
              class="checkbox"
              v-model="allow_vote_count"
              name="board[allow_vote_count]"
            />
          </div>
          <div class="ml-4">
            <b>Hide count of votes</b><br/>
            <span>The number of votes on ideas will not be displayed to users</span>
          </div>
        </div>


        <div>
         <h3 class="mt-6 text-lg font-semibold text-neutral-800">
           Privacy settings
          </h3>
          </div>

        <div class="toggle-wrapper mt-4 text-neutral-800 flex">
          <div>
            <input
              type="checkbox"
              class="checkbox"
              v-model="is_private"
              name="board[is_private]"
            />
          </div>
          <div class="ml-4">
            <b>
              Set board as private
            </b><br/>
            <span>This board will be hidden from the public and only accessible to admins of this project.
            </span>
          </div>
        </div>




        <div class="mt-6">
          <input
            type="submit"
            name="commit"
            value="Save board"
            :disabled="this.current_plan === 'lite' && this.boards_length >= 1"
            class="btn-main"
          />
        </div>
      </form>
    </div>

    <div class="grid-span-1 flex flex-col items-center">
      <div
        class="w-full max-w-sm text-left text-neutral-700 font-bold uppercase tracking-wider text-sm flex items-center leading-none"
      >
        <div class="bg-red-500 w-2 h-2 rounded-full mr-2 animate-pulse"></div>
        <span>Live preview</span>
      </div>
      <div class="bg-white rounded-lg border px-5 py-4 max-w-sm mt-2">
        <h4 class="text-lg font-bold text-dblue-500">
          {{ this.intro_heading }}
        </h4>

        <!-- prettier-ignore -->
        <p class="whitespace-pre-line mt-2 text-neutral-800">{{ this.intro_text }}
        </p>

        <form action="#" class="">
          <div class="mt-3">
            <label for="title" class="label inline-block">{{
              this.form_title
            }}</label>
            <input
              id="post_title"
              autocomplete="off"
              type="text"
              name="title"
              class="input"
              :placeholder="this.form_title_placeholder"
              value
              disabled
            />
          </div>

          <div class="mt-3">
            <label for="content" class="label inline-block">{{
              this.form_content
            }}</label>
            <textarea
              id="post_content"
              rows="4"
              required="required"
              name="content"
              class="input"
              :placeholder="this.form_content_placeholder"
              value
              disabled
            ></textarea>
          </div>

          <p class="whitespace-pre-line mt-2 text-neutral-800 text-left break-words"
          >{{this.form_footer_text}}</p>

          <div class="mt-3">
            <button
              type="button"
              class="btn-main w-full text-center block"
              disabled
            >
              {{ this.form_button_text }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import PlanAlert from "../projects/settings/plan_alert"

export default {
  props: [
    "original-board",
    "original-project",
    "auth-token",
    "form-action",
    "form-method",
    "errors",
    "all-boards",
    "all-status",
    "selected-status",
    "status-name",
    "current-plan",
    "boards-length",
  ],
  data: function () {
    return {
      form_action: this.formAction,
      form_method: this.formMethod,
      form_token: this.authToken,
      project_subdomain: this.originalProject.subdomain,
      name: this.originalBoard.name,
      slug: this.originalBoard.slug,
      allow_vote_count: JSON.parse(this.originalBoard.allow_vote_count),
      is_private: JSON.parse(this.originalBoard.is_private),

      page_heading: this.originalBoard.page_heading,
      page_description: this.originalBoard.page_description,
      intro_heading: this.originalBoard.intro_heading || "Add your idea",
      intro_text:
        this.originalBoard.intro_text ||
        "We value your feedback. You can vote for existing ideas, discuss them or add your own.",
      form_title: this.originalBoard.form_title || "Title",
      form_title_placeholder:
        this.originalBoard.form_title_placeholder || "Something short",
      form_content: this.originalBoard.form_content || "Description",
      form_content_placeholder:
        this.originalBoard.form_content_placeholder ||
        "Write about your idea in more detail here",
      form_button_text: this.originalBoard.form_button_text || "Add idea",
      form_footer_text: this.originalBoard.form_footer_text,
      allStatuses: this.allStatus,
      selectedValue: "",
      hide_not_set: this.originalBoard.hide_not_set_status,
      current_plan: this.currentPlan,
      boards_length: this.boardsLength,
    };
  },
  mounted() {
    this.selectedValue = this.selectedValuetSet()
  },
  components: {
    PlanAlert,
  },
  computed: {},
  methods: {
    slugify: function (text) {
      text = text.toString().toLowerCase().trim();

      const sets = [
        { to: "a", from: "[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]" },
        { to: "c", from: "[ÇĆĈČ]" },
        { to: "d", from: "[ÐĎĐÞ]" },
        { to: "e", from: "[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
        { to: "g", from: "[ĜĞĢǴ]" },
        { to: "h", from: "[ĤḦ]" },
        { to: "i", from: "[ÌÍÎÏĨĪĮİỈỊ]" },
        { to: "j", from: "[Ĵ]" },
        { to: "ij", from: "[Ĳ]" },
        { to: "k", from: "[Ķ]" },
        { to: "l", from: "[ĹĻĽŁ]" },
        { to: "m", from: "[Ḿ]" },
        { to: "n", from: "[ÑŃŅŇ]" },
        { to: "o", from: "[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
        { to: "oe", from: "[Œ]" },
        { to: "p", from: "[ṕ]" },
        { to: "r", from: "[ŔŖŘ]" },
        { to: "s", from: "[ßŚŜŞŠ]" },
        { to: "t", from: "[ŢŤ]" },
        { to: "u", from: "[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
        { to: "w", from: "[ẂŴẀẄ]" },
        { to: "x", from: "[ẍ]" },
        { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
        { to: "z", from: "[ŹŻŽ]" },
        { to: "-", from: "[·/_,:;']" },
      ];

      sets.forEach((set) => {
        text = text.replace(new RegExp(set.from, "gi"), set.to);
      });

      text = text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text

      return text;
    },
    selectedValuetSet() {
      let result = this.allStatuses.filter(status => status.id === (this.selectedStatus?.id || ''))
      if (result.length) return this.selectedStatus.id
      result = this.allStatuses.filter(status => status.name === this.statusName.not_set)
      return result[0].id;
    },
  },
  watch: {
    name: function (newName) {
      this.slug = this.slugify(newName);
    },
  },
};
</script>

<style scoped>
.error-box {
  margin-top: 2rem;
  border: 1px solid red;
  padding: 1rem;
  border-radius: 4px;
}

.error-box li {
  font-weight: bold;
}

input[type=checkbox] {
  width: 5mm;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 5mm;
  border: 0.1mm solid #00000052;
  border-radius: 5px;
  margin-top: 3.5px;
}

input[type=checkbox]:checked:after {
  margin-left: 1.5mm;
  width: 1.5mm;
  margin-bottom: 1mm;
  height: 4mm;
  border: solid green;
  border-width: 0 1mm 1mm 0;
  transform: rotate(45deg);
  content: "";
  display: inline-block;
}

@media only screen and (max-width: 420px){
  #span-id{
    font-size: smaller;
  }
}
</style>
