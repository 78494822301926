<template>
  <span
    v-if="this.status !== this.statusName.not_set"
    class="status text-sm font-medium rounded-full tracking-wide inline-block"
    :style="{'color': color}"
    >{{ this.status }}</span
  >
</template>

<script>
export default {
  props: ["status", "color", "status-name"],
  data: function () {
    return {};
  },

  filters: {
    humanize: function (str) {
      if (str) {
        return str.replace(/_/g, " ");
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>

</style>
