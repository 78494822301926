<template>
  <div class="relative gap-2 flex-shrink-0 ml-auto" ref="scontainer">
    <transition
      name="show"
      mode="out-in"
      @before-enter="fixWidth"
      @after-enter="focusInput"
      appear
    >
    <div 
      class="w-full relative sm:inset-auto sm:w-auto gap-2 flex-shrink-0 pb-3 md:pb-0 md:flex items-center px-4 sm:px-0 md:pr-4"
      v-if="isSearchBoxVisible"
    >
      <form
          :action="this.search_url"
          class="relative md:w-64 max-w-full"
        >
          <input
            type="text"
            id="term"
            name="term"
            class="input border border-dblue-200 bg-white rounded-md py-1 px-3 w-full"
            :placeholder="$t('Search ideas')+'...'"
            ref="searchBox"
          />
          <button
            type="submit"
            class="absolute right-0 top-center p-3 transform -translate-y-1/2 text-dblue-500 hover:text-project-500"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </form>
      </div>

      <a
        href="#"
        class="p-4 flex items-center text-dblue-500 hover:text-project-500 hover:no-underline transition duration-150 ease-in-out"
        @click.prevent="openSearch()"
        v-else
      >
        <svg
          class="w-5 h-5 sm:mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
        <span class="hidden sm:block">{{ $t('Search') }}</span>
      </a>
    </transition>
  </div>
</template>

<script>
import searchIcon from "../images/icons/search-fat.svg";
import closeIcon from "../images/icons/close.svg";

export default {
  props: ["search_url"],
  data() {
    return {
      isSearchBoxVisible: false,
      searchIcon,
      closeIcon,
    };
  },
  components: {
    searchIcon,
    closeIcon,
  },
  methods: {
    openSearch() {
      this.isSearchBoxVisible = true;
    },

    focusInput() {
      if (this.$refs.searchBox) {
        this.$refs.searchBox.focus();
      }
    },

    fixWidth() {
      if (this.$refs.searchBox) {
        this.$refs.scontainer.classList.add("order-4", "w-full", "md:order-2", "md:w-auto");
      }
    },
  },
};
</script>

<style scoped>
.top-center {
  top: 50%;
}

.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translateX(10px);
  position: absolute;
}

.show-enter-to,
.show-leave {
  opacity: 1;
  transform: translateX(0);
  position: relative;
}

.show-enter-active,
.show-leave-active {
  transition: opacity, transform 150ms ease-out;
}
</style>
