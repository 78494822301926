<template>
  <div>
    <div class="relative" id="customize-roadmap-container">
      <transition name="fadeIn">
        <div
          class="drawer-backdrop"
          v-if="labelsOpen"
          @click.self="labelsOpen = false"
        ></div>
      </transition>
      <transition name="slideFromRight">
        <div class="drawer overflow-y-auto" v-if="labelsOpen" id="roadmap-status-slide">
          <div :class="screenWidth < 440 ? 'drawerContent' : 'drawer__content' " id="roadmap-status-header">
            <div class="flex flex-row items-end justify-between " >
              <div id="roadmap-edit-title">
                <h3 class="mt-2 font-bold text-xl text-neutral-800">
                  Edit Roadmap
                </h3>
              </div>
              <div id="roadmap-status-close-icon">
                <a href="#" @click.prevent="labelsOpen = false" title="Close drawer" class="close_color">
                  <simple-svg
                    :filepath="closeIcon"
                    :width="'20px'"
                    :height="'20px'"
                  />
                </a>
              </div>
            </div>
            <p class="mt-1 text-neutral-800 text-wrap">
              {{ $t("Choose which statuses are shown in your roadmap, change their order on screen") }}.
            </p>

            <div class="labels" id="all-status-roadmap">
              <draggable
              @change="sortingStatus"
              :list="filteredStatus"
              :animation="200"
              ghost-class="moving-card"
              filter=".action-button"
              class="w-full max-w-md"
              @start="drag=true"
              @end="drag=false"
              :options="{handle:'.handle'}"
              id="draggable-roadmap-status"
              >
                <transition-group>
                  <template v-for="label in filteredStatus">
                    <RoadmapStatusLabel
                      :original-label="label"
                      :key="label.id"
                      :project-id="projectId"
                      @updatedStatusRoadmap="updatedStatusRoadmap($event)"
                      :class="'roadmap-status-'+label.id"
                      :id="'roadmap-status-'+label.id"
                    ></RoadmapStatusLabel>
                  </template>
                </transition-group>
              </draggable>
              <div class="mt-2 flex flex-row justify-end adjust-message" :class="successMessage ? 'messageVisible' : 'messageHidden'" id="change-successfull-msg">
                <p class="successMessageAlert py-4 px-6 flex">{{ $t("Your changes have been saved successfully") }}</p>
              </div>
            </div>

          </div>

        </div>
      </transition>

      <small>
        <button class="btn-size text-sm border border-neutral-300 rounded-md bg-gradient-to-b from-white to-neutral-100 shadow-sm px-3 py-1.5 text-dblue-500 flex items-center focus:to-white focus:border-neutral-400 focus:outline-none" @click.prevent="getStatuses" id="roadmap-status-btn">
        <svg
            :width="'16px'"
            :height="'16px'"
            class="mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            v-if="!button_status"
          >
            <path
              fill="currentColor"
              d="M7,6H6V3A1,1,0,0,0,4,3V6H3A1,1,0,0,0,3,8H7A1,1,0,0,0,7,6ZM5,10a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V11A1,1,0,0,0,5,10Zm7,8a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,12,18Zm9-8H20V3a1,1,0,0,0-2,0v7H17a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-2,4a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,19,14Zm-5,0H13V3a1,1,0,0,0-2,0V14H10a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z"
            /></svg
          >
          <span>Edit Roadmap</span>
        </button>
      </small>
    </div>
  </div>
</template>

<script>
import closeIcon from "../images/icons/x.svg";
import RoadmapStatusLabel from "./roadmap_status_label";
import EventBus from "event_bus";
import draggable from 'vuedraggable'


export default {
  props: ["post-status", "project-id", "button_status", "status-ids", "status-name"],

  data: function() {
    return {
      allStatuses: this.postStatus,
      labelsOpen: false,
      closeIcon,
      filteredStatus: [],
      statusId: this.statusIds,
      successMessage: false,
      screenWidth: null,
    };
  },
  mounted: function() {
    window.addEventListener("keydown", e => {
      if (e.key == "Escape") {
        this.labelsOpen = false;
      }
    });
    this.filteredStatus = this.filterByNotSet()
    EventBus.$on("status_label", (status) => {
      this.allStatuses = status
    });
    EventBus.$on("status_ids", (ids) => {
      this.statusId = ids
    });
    this.screenWidth = window.innerWidth;
  },

  components: {
    closeIcon,
    RoadmapStatusLabel,
    draggable,
  },
  methods: {
    filterByNotSet () {
      const result = this.allStatuses.filter(status => status.name !== this.statusName.not_set)
      const filterStatus = this.statusId.map(id => {
        return result.find(status => status.id === id);
      })
      return filterStatus;
    },

    updatedStatusRoadmap(status) {
      this.successMessage=true;
      setTimeout(()=>{ this.successMessage = false; }, 1000);
      EventBus.$emit("status_label", status)
    },

    sortingStatus() {
      const statusIds = this.filteredStatus.map (status => {
        return status.id
      })
      let params = new FormData();

      params.append("status[project_id]", this.projectId);
      params.append("status[status_ids]", statusIds);
      Rails.ajax({
        url: "/statuses/filter_status",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.statusId = data.status_id;
          this.successMessage=true;
          setTimeout(()=>{ this.successMessage = false; }, 1000);
          EventBus.$emit("status_ids", data.status_id)
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    },
    getStatuses () {
      Rails.ajax({
        url: "/statuses" + "?project_id=" + this.projectId,
        type: "GET",
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data =>  {
          this.allStatuses = data.status_label;
          this.statusId = data.status_ids;
        },
        error: (data, status) => {
        }
      });
      this.labelsOpen = !this.labelsOpen
    },
  },
  watch: {
    allStatuses: function() {
      this.filteredStatus = this.filterByNotSet()
    }
  }
};

</script>

<style scoped>

.messageVisible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}
.messageHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 2s linear;
}
.adjust-message {
  padding-bottom: 110px;
}
.btn-size{
  width: 100%;
}
.close_color {
  color: #525252;
}
</style>
